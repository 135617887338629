<template>
  <div class="column">
    <div class="spinner">
      <div class="double-bounce1" />
      <div class="double-bounce2" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLoader",
};
</script>
