<template>
  <div class="column is-12">
    <h1 class="title is-5 has-text-grey has-text-centered mt-5">No commands found!</h1>
  </div>
</template>

<script>
export default {
  name: "CommandNotFound",
};
</script>

<style scoped lang="scss"></style>
